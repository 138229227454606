import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const About = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('About Us')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>About Us</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">About Us</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <p>Welcome to {Application.ORG_DISPLAY_NAME}! We take immense pride in being your go-to destination for travel solutions that seamlessly blend convenience and affordability. As a dedicated third-party consolidator, we excel in presenting you with unparalleled flight deals and incredible offers from all major US airlines.</p>
      <p>Our central mission revolves around turning your travel aspirations into tangible experiences. With top-notch booking services, we ensure a journey that's smooth from takeoff to landing. At {Application.ORG_DISPLAY_NAME}, your satisfaction and the creation of unforgettable travel memories take precedence. Our mission is simple yet powerful: to create a travel journey that's both seamless and budget-friendly, while genuinely enjoyable. With years of industry expertise, we've forged strong partnerships with major US airlines, granting us the privilege to offer you exclusive deals that you won't find elsewhere. Whether you're embarking on a solo adventure, planning a romantic escape, or organising a family vacation, {Application.ORG_DISPLAY_NAME} is here to cater to all your needs.</p>
      <p>What truly distinguishes us is our unwavering commitment to customer satisfaction. Our team of dedicated travel experts is at your service around the clock, ready to guide you at every step of your journey. From finding the perfect flight to ensuring a smooth booking process, we go above and beyond to ensure your travel experience is nothing short of exceptional.</p>
      <p>As you navigate our user-friendly website, you'll unveil a realm of endless possibilities. Dive into a diverse range of flight options, take advantage of incredible offers, and entrust the details to us, allowing you to focus on creating cherished memories.</p>
      <div className="row mt-4 mb-2">
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-thumbs-up"></i>
         </div>
         <h3>Best Deal Offer</h3>
         <p>
          Find our lowest deal to destinations worldwide, guaranteed
         </p>
        </div>
       </div>
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-paper-plane"></i>{' '}
         </div>
         <h3>Easy Booking</h3>
         <p>
          Search, select and save - the fastest way to book your trip
         </p>
        </div>
       </div>
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-eye"></i>{' '}
         </div>
         <h3>24/7 Customer Care</h3>
         <p>
          Get award-winning service and special deals by calling{' '}
          {Application.SUPPORT_PHONE}
         </p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default About;