import {PAGE_IDS} from '../Constants';

const Application = {
  ORG: 'MyAeroTrip',
  ORG_DISPLAY_NAME: 'MyAeroTrip',
  ORG_ADD_LINE1: '4100 Spring Valley Rd #635, Dallas,',
  ORG_ADD_LINE2: 'TX 75244, USA',
  STORAGE_HOST: 'https://storage.googleapis.com',
  PRODUCTION_API_BASE_URL: 'https://myfaresinfo.com/alpha',
  LOCAL_API_BASE_URL: 'http://localhost:8080/alpha',
  STORAGE_URL_STATIC_ASSETS_PATH: 'https://storage.googleapis.com/sfc-static',
  DOMAIN: 'myaerotrip.com',
  SUPPORT_PHONE: '+1 (000) 000-0000',
  CONTACT_EMAIL: 'support@myaerotrip.com',
  MAP_ADDRESS: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.44076999!2d-96.83980242396657!3d32.939370473596185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c26d504cf434f%3A0x5da63ddc9ac3728f!2s4100%20Spring%20Valley%20Rd%20%23635%2C%20Dallas%2C%20TX%2075244%2C%20USA!5e0!3m2!1sen!2sin!4v1694403768785!5m2!1sen!2sin',
  CALL_NOW_POPUP_HEADING_TEXT: 'Call now for Booking, Changes and Cancellation',
  CALL_NOW_POPUP_SAVE_PRICE: 30,
  CALL_NOW_POPUP_SAME_PAGE_TIMEOUT_SEC: 10,
  DISCLAIMER: `MyAeroTrip, is an independent Travel service provider that provides affordable travel services. We are not associated with Airlines in any way, all branding is purely for demonstrative purposes only and does not signify any association with any Airlines or organization.`,
  GA_ID: '',
  PAGE_ID_TO_PHONE: {
    [PAGE_IDS.FLIGHT]: '+1 (000) 000-0000'
  }
};
export default Application;
