import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import {PageUrl} from './Constants';
import Application from './config/application';

const CancelRefund = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Cancellation & Refund Policy')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Cancellation & Refund Policy</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Cancellation & Refund Policy</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
  
      <p>At {Application.ORG_DISPLAY_NAME}, we understand that travel plans can change unexpectedly. We are committed to providing you with transparent and comprehensive guidelines concerning cancellations and refunds. We kindly ask you to review the following policy carefully before finalising any bookings.</p>
      <p><strong>Booking Cancellations:</strong></p>
      <p>Cancellation policies are subject to variations based on the specific airline, fare type, and route of your travel. We advise you to acquaint yourself with the precise cancellation conditions associated with your reservation.</p>
      <p>Cancellation procedures conducted through our website will adhere to the same outlined policies.</p>
      <p><strong>Refund Eligibility:</strong></p>
      <p>Determining refund eligibility depends on the fare regulations set by the airline and the type of ticket purchased.</p>
      <p>Some tickets might not be eligible for refunds, while others could qualify for partial or full reimbursements, contingent on the fare stipulations.</p>
      <p><strong>Refund Process:</strong></p>
      <p>After the airline approves a refund, {Application.ORG_DISPLAY_NAME}.com will proceed in accordance with the stipulated refund policy of the airline.</p>
      <p>Refunds will be routed back to the initial payment method used during the booking.</p>
      <p><strong>Processing Time:</strong></p>
      <p>The time required to process refunds varies and is contingent on the airline's policies. In certain cases, refund processing might extend over several weeks.</p>
      <p><strong>Fee Deductions:</strong></p>
      <p>Airlines might impose specific cancellation fees, change fees, or administrative costs in line with their individual policies.</p>
      <p>For its part, {Application.ORG_DISPLAY_NAME}.com might levy a service fee to manage the cancellation and refund processes. This fee is designed to cover the administrative expenses tied to refund operations.</p>
      <p><strong>Changes To Policy:</strong></p>
      <p>It is within the rights of {Application.ORG_DISPLAY_NAME} to revise or amend this Cancellation & Refund Policy whenever necessary. Any modifications will become effective immediately upon their posting on our official website.</p>
      <p>By choosing {Application.ORG_DISPLAY_NAME} for your booking, you acknowledge that you have perused, comprehended, and consented to our Cancellation & Refund Policy. Should you require further clarification or assistance, please feel free to get in touch with our dedicated customer support team.</p>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default CancelRefund;