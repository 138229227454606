import React from 'react';
import {useLocation} from 'react-router-dom';
import Application from '../config/application';

const AirlineHomeDesc = () => {
  const location = useLocation();
  const path = location.pathname.toLowerCase();

  return (
    <div className="container">
      <div className="bg-white shadow-md rounded p-4 mt-4">
        {path.includes('jetblue-airlines') ? (
          <>
            <h4>
              Book JetBlue Airlines Tickets with {Application.ORG_DISPLAY_NAME}
            </h4>
            <p>
              JetBlue Airlines, commonly known as JetBlue or sometimes JetBlue
              Airlines, is an American low-cost airline. The airline has several
              bases across the United States, but its main hub is at John F.
              Kennedy International Airport. The airline flies to about 100
              destinations and has a fleet of more than 250 aircraft, making it
              the seventh largest airline in the United States. The airline
              mainly offers domestic flights within the United States, but also
              serves other parts of North America, as well as Central and South
              America. In general, the plane flies to more than 100
              destinations.
            </p>
            <p>
              The airline was founded in 1998, initially under the name and
              brand "NewAir". In 2000, the airline began flights in 2000 with
              flights from Buffalo to Fort Lauderdale. This plane was originally
              designed as a cheap car, but over time, it added amenities to its
              services, such as in-flight entertainment, food and more. Although
              the airline has many airports across the United States, it often
              offers flights between most destinations in America. Its main
              destinations are New York JFK, Boston, Fort Lauderdale, Orlando,
              Long Beach, Washington-National, Newark and Tampa. New York JFK,
              Boston and Fort Lauderdale are important destinations for flights,
              with more than 100 daily departures from these airports.
            </p>
            <div>
              <h5>Service You Can Expect</h5>
              <p>
                With JetBlue, you can expect quality service. Delicious snacks
                and drinks are served on every flight. Soft drinks are available
                for purchase. Enjoy movies and music on DIRECTV during your
                flight. It's a free job. Connect with Fly-Fi, a high-speed
                Internet connection. If you need more space, book a seat with
                more legroom. An in-seat charger will help you keep your devices
                charged during the flight.
              </p>
              <h5>Class of Travel</h5>
              <p>
                Choose your seat for less money in the big house. The Mint
                Experience offers the perfect seating for your comfort in
                certain areas. A delicious and varied "small plate menu" from
                the famous New York restaurant "Saxon + Parole" is served during
                the flight. You will find artisanal ice cream and bread from the
                'Hot Bread Kitchen' and coffee from the 'Brooklyn Roasting
                Company'. Enjoy the world's best wine aboard JetBlue.
              </p>
              <h5>What is the JetBlue Airlines Baggage Policy?</h5>
              <p>
                When you're ready to plan a vacation with JetBlue, one thing
                that comes to mind is luggage. What kind of luggage can you
                carry? How much is the maximum allowed?
              </p>
              <p>
                All these questions run through your head and you can't help
                worrying about them. However, with your reservation on JetBlue
                flights, you can rest assured. They have a luggage transfer
                policy and service to ensure you reach your destination safely.
                There are several options for shipping your luggage on JetBlue
                flights. Here is the detailed information about the maximum
                allowed:
              </p>
              <h5>Carry-On Luggage</h5>
              <p>
                JetBlue recently updated its baggage allowance policy.
                Therefore, each JetBlue fee may include personal items. It will
                also fit under the front seat.
              </p>
              <p>
                Additionally, this item can be your FAA-approved backpack,
                handbag, laptop bag, or pet carrier. Also, the dimensions of
                your personal belongings cannot exceed 17 x 13 x 8 inches.
              </p>
              <p>
                In addition, passengers with all advanced fares on JetBlue
                reservations can bring one bag that will fit in the upper part
                of their seat. But the size of this bag, including wheels,
                handles, etc. not to exceed 22 x 14 x 9 inches.
              </p>
              <h5>Online Check-In</h5>
              <p>
                JetBlue Airlines offers online and mobile check-in for
                passengers. This service is available on the JetBlue website
                under “Online Check-In” and is available 24 hours prior to the
                scheduled departure time. You can also log into the airline's
                app, which can be downloaded by searching for "JetBlue" in your
                phone's app store.
              </p>
              <h5>Airport Check-In</h5>
              <p>
                If you do not check in online for your JetBlue flight, you must
                check in at the airport using the self-service check-in kiosks.
              </p>
              <p>
                Passengers should arrive at the airport two hours before the
                scheduled departure time and should register for check-in upon
                arrival at the airport. For domestic flights, passengers must
                arrive 1h30 before departure and two hours before departure for
                international flights.
              </p>
            </div>
          </>
        ) : path.includes('spirit-airlines') ? (
          <>
            <h4>
              Spirit Airlines Flight Reservations on{' '}
              {Application.ORG_DISPLAY_NAME}
            </h4>
            <p>
              Spirit Airlines (NK) is a North American low-cost airline based in
              Miramar, Florida. The airline operates approximately 500 scheduled
              flights per day to 67 destinations in the United States, the
              Caribbean, Mexico and Latin America. Ranked as the 8th largest
              commercial airline in North America, Spirit Airline's main
              operating hubs are located in Atlantic City, Chicago-O'Hare,
              Dallas/Fort Worth, Detroit, Fort Lauderdale, Orlando and Las
              Vegas. With 120 Airbus aircraft, this airline will soon expand to
              57 more aircraft. Now you can travel to all your favorite
              destinations without breaking the bank by booking cheap Spirit
              Airlines tickets with {Application.ORG_DISPLAY_NAME}. Save big by booking Spirit
              Airlines with us.
            </p>
            <div>
              <h5>Spirit Airlines In-Flight Amenities</h5>
              <p>
                It is always a fun experience flying with Spirit Airlines. To
                enjoy access to a world of comfort and great experience book
                cheap Spirit Airlines tickets with us and cut down on your
                travel expenses.
              </p>
              <ul>
                <li>
                  Seat specifications may vary slightly depending on the
                  aircraft.
                </li>
                <li>
                  Travelers can choose seamless online booking and check-in.
                </li>
                <li>
                  The ship staff and crew are very friendly, polite and customer
                  friendly.
                </li>
                <li>
                  The airline provides reliable, fast and efficient service to
                  its customers.
                </li>
                <li>
                  Spirit Airlines offers the lowest possible rate to its valued
                  customers.
                </li>
                <li>
                  The airline offers the largest ULCC network in the United
                  States, Latin America and the Caribbean.
                </li>
                <li>
                  There are luxury leather seats that allow the passenger to
                  stow luggage under the seat.
                </li>
                <li>
                  Travelers can also use their debit or credit card to purchase
                  a variety of food and beverages.
                </li>
                <li>
                  Customers also have access to insurance services, optional
                  hotel accommodations, cars, vacation packages and cruise
                  services.
                </li>
              </ul>
              <h5>Exciting offers at Spirit Airlines</h5>
              <p>
                Air travel can be the most exciting thing for many people.
                Still, when they know about the process, they must follow for
                booking the tickets, and before taking the flight can sometimes
                be very hectic for people, especially in a hurry. This is why
                you must get your tickets booked from a better place and on a
                good flight. Spirit Airlines is the answer to all these
                questions. when you have to take a flight for your vacation or
                your official trip, you can get everything done within no time.
              </p>
              <h5>Affordable flights</h5>
              <p>
                You can also get all the details about the flight, like the
                flight number, the route which the flight will take to reach the
                destination, the fuel stations, and many others. You can also
                get your doubts and queries resolved from the customer service
                that is always active for the customers along with that, you can
                confirm your boarding gate and time as well very easily. This is
                the time you start booking flights and prepare for the vacations
                and things that you are going to do on that. Get the best prices
                and offers on the tickets while you book them as early as
                possible.
              </p>
              <h5>Spirit Airlines Web Check-In</h5>
              <p>
                Spirit Airlines' web check-in service allows you to choose your
                seat at home or anywhere else based on availability and
                priority. Go online and print your boarding pass at home to skip
                the airport hassles. The online check-in process starts 24 hours
                and closes one hour before flight departure, allowing passengers
                to add any extras such as luggage or other assigned seats.
              </p>
              <h5>Free Spirit Membership</h5>
              <p>
                With a Free Spirit Mastercard from Spirit, frequent flyers can
                earn 2 miles on ever dollar spent. Earn free travel with easier
                booking and stay updated with special updates and offers
                exclusive to Free Spirit members.
              </p>
              <p>
                Spirit knows that most customers want low prices. They make this
                dream a reality every day by selling Bare Fare™, which means no
                one is stuck paying high airfares, which (and other airlines)
                come with and many expensive extras that you may not need. If
                you want to optimize your flight experience, Spirit offers you
                Frill Control™, so you can choose only the services you want to
                pay for - such as baggage, baggage or seat service, while
                keeping the cost of Spirit in check. you. Travel is cheaper (up
                to 40% less) than other airlines. With Spirit, you have control
                over what you spend and save. So you can fly to more places,
                more times, or have more money in your pocket when you arrive.
              </p>
              <h5>What you need to know when flying Spirit:</h5>
              <div>
                <h5>Spirit is the Home of the Bare Fare</h5>
                <p>
                  The money of the spirit is not limited. No "free" bags There
                  is no "free" drink. Other airlines build these options right
                  into their ticket price, making you pay more. A spirited
                  ticket gets you private from A to B. Everything else is extra.
                </p>
                <h5>What to Know About Spirit's BIG FRONT SEAT</h5>
                <p>
                  Anyone who reserves a ghost plane can also reserve their BIG
                  FRONT SEAT. It's a cheap way to fly first class to your
                  destination. These leather seats add conformability to their
                  executive style design. The seat is 25% larger and provides
                  35% more legroom. And since they are in the first two rows of
                  the flight, those who book them for their Spirit Airline
                  flight will benefit from joining Group 2. All other procedures
                  for your Spirit Airline flight will - like behavior. Consumers
                  still pay regularly for their carry-on bags and food and drink
                  purchases.
                </p>
                <h5>
                  Need a handbag or checked bag? Buy them online at Spirit.com
                </h5>
                <p>
                  A personal item is included in the Fare Fare - something like
                  a wallet - which can fit completely into the smallest box (16"
                  x 14" x 12"). or a purse, is a little extra.
                </p>
                <p>
                  If you know you'll need a checked or larger bag, buy it early
                  online at Spirit.com to save major moolah. You can't (yet) buy
                  bags when you book here, but you can go over to Spirit.com to
                  add them to any confirmed booking.
                </p>
                <p>
                  <b>NOTE:</b> Spirit is one of the best airlines in the
                  country, saving you money on gas. They got that name by making
                  a tough choice: limit the weight of the check to 40 kilograms.
                  They know it's boring. But when you travel light, you will
                  save weight.
                </p>
              </div>
            </div>
          </>
        ) : path.includes('southwestwest-airlines') ||
          path.includes('southwest-airlines') ? (
          <>
            <h4>Southwest Airlines Reservations on {Application.ORG_DISPLAY_NAME}</h4>
            <p>
              Southwest Airlines is an American low-cost airline headquartered
              in Dallas, Texas. Founded in 1966 by Herbert Keeler and Rollin
              King, the airline was incorporated in 1967 as Southwest Airlines
              under its current name Southwest Airlines in 1971. Southwest
              Airlines is primarily a low-cost airline that operates primarily
              in the United States and nearby international markets, using the
              Boeing 737 exclusively. If you're looking for a cheap flight to
              the United States and neighboring countries, book at Southwest
              Airlines Air Save big on your trip with {Application.ORG_DISPLAY_NAME}.
            </p>
            <div>
              <h5>Best Airlines Southwest Airlines</h5>
              <p>
                People nowadays have to visit a lot of different kinds of places
                due to work and even the students fly to different countries to
                attend a variety of exhibitions, seminars, and many other
                things. Traveling from one place to another has become very
                common, but there are still many complex things that not
                everyone can understand, and there are also people who do not
                have time for such things as they have to take a flight on an
                emergency basis.
              </p>
              <p>
                This is why you should have someone who can book all your flight
                tickets easily and also get you amazing discounts and offers
                because money is something you must spend a lot when you are
                booking flights urgently. This is where SouthWest Airlines can
                be of great help to take you from your place to your destination
                safely and at the best prices in the market. You need not have
                to waste your time on completing unnecessary things prepare for
                the vacation or the work that you are going to do and leave the
                rest to them. Make sure to book the tickets on time to avail
                yourself of the best offers and get your work done. Your
                destination is waiting for you.
              </p>
              <h5>Southwest Airlines Web Check In</h5>
              <p>
                Travelers who choose Southwest Airlines Online Check-in can
                leave their checked bag at the baggage claim counter for a
                faster and more convenient check-in experience. You can also use
                Southwest Airlines priority counters (available at some
                airports) to save even more time. However, there is an
                additional charge for this service.
              </p>
              <h5>Baggage Policy:</h5>
              <div>
                <h5>Checked baggage</h5>
                <p>
                  Weight up to 50 pounds and size up to 62 inches (length +
                  width + height) per luggage is allowed by Southwest Airlines.
                  Make sure you don't carry any sharp objects to prevent
                  injuries.
                </p>
                <h5>Carryon Baggage</h5>
                <p>
                  Carryon items are limited to one small personal item and one
                  bag. Carryon Baggage Dimensions are 10 x 16 x 24 inches.
                </p>
                <p>
                  Personal items include cameras, food containers, purses,
                  briefcases, or laptops (case included). Personal Item
                  Dimensions are 18.5 x 8.5 x 13.5 inches
                </p>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AirlineHomeDesc;
