import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Destinations = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Destinations')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Destinations</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Destinations </li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <p>Discover boundless possibilities with {Application.ORG_DISPLAY_NAME}, No matter if you're in search of adventure, relaxation, or exploration, we're here to steer you towards your dream destinations. Uncover the diverse range of locations we offer and let your travel voyage kick-start.</p>
      <strong>Enchanting Getaways:</strong>
      <p>Unveil the allure of faraway lands and immerse yourself in cultures that will leave you spellbound. From the sun-drenched beaches of Bali to the vibrant markets of Marrakech, our enchanting getaways promise unforgettable experiences.</p>
      <strong>Cosmopolitan Retreats:</strong>
      <p>If city lights and contemporary luxuries beckon, our cosmopolitan retreats have you covered. Roam the bustling streets of New York, savour the flavours of Tokyo, or admire the architectural marvels of Paris.</p>
      <strong>Nature's Escapes:</strong>
      <p>For those yearning for tranquillity in the embrace of nature, our nature escapes offer serenity and breathtaking landscapes. Embark on a journey to the serene lakes of Switzerland or lose yourself in the lush rainforests of Costa Rica.</p>
      <strong>Thrilling Adventures:</strong>
      <p>For the thrill-seekers and adventurers, our destinations offer heart-pounding experiences. Trek through the Inca Trail to Machu Picchu, dive into the depths of the Great Barrier Reef, or conquer the peaks of the Rocky Mountains.</p>
      <p>{Application.ORG_DISPLAY_NAME}, we carefully curate destinations that cater to every traveller's aspirations. Whether you're a solo explorer, a couple in search of romance, a family needing quality time, or a group of friends craving adventure, we have the perfect destination awaiting you. Let us guide you to a world of extraordinary experiences. Your journey starts here.</p>
  
     </div>
    </div>
    <section className="section">
     <div className="container">
      <h2 className="text-9 fw-500 text-center">Popular Destinations</h2>
      <p className="lead text-center mb-4">
       World's leading Flight Booking website.
      </p>
      <div className="row g-4 banner">
       <div className="col-md-8">
        <div className="item rounded h-100">
         <div className="caption text-center">
          <h2 className="text-7">Chicago</h2>
         </div>
         <div className="banner-mask"></div>
         <img className="img-fluid h-100" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/myaerotrip/22.jpg`} alt="Chicago" />
        </div>
       </div>
       <div className="col-md-4">
        <div className="row g-4">
         <div className="col-12">
          <div className="item rounded">
           <div className="caption text-center">
            <h2>Mimami</h2>
           </div>
           <div className="banner-mask"></div>
           <img className="img-fluid" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/myaerotrip/23.jpg`} alt="Mimami" />
          </div>
         </div>
         <div className="col-12 mt-lg-3">
          <div className="item rounded">
           <div className="caption text-center">
            <h2>London</h2>
           </div>
           <div className="banner-mask"></div>
           <img className="img-fluid" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/myaerotrip/24.jpg`} alt="London" />{' '}
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </section>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default Destinations;