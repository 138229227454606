import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Terms = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Terms and Conditions')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Terms and Conditions</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Terms and Conditions</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <div>
       <p>Introducing {Application.ORG_DISPLAY_NAME}'s Terms & Conditions, meticulously crafted to ensure absolute transparency during your travel escapades. By opting for {Application.ORG_DISPLAY_NAME} for your voyage, you enter into an agreement to uphold these terms, fostering mutual understanding between us. These terms diligently oversee our service usage, offering crucial insights into your rights and obligations as a traveller. From reservation processes to payment directives. Prior to proceeding with any reservations or transactions, we urge you to thoroughly review these terms.</p>
       <p><strong>Booking Reservations and Payments:</strong></p>
       <ul>
        <li>Every reservation made through {Application.ORG_DISPLAY_NAME} is contingent on availability and the conditions stipulated by the respective airlines.</li>
        <li>At the time of booking, payment is mandatory. All prices are denoted in US dollars, unless explicitly stated otherwise.</li>
       </ul>
       <p><strong>Accuracy of Information:</strong></p>
       <p>While we leave no stone unturned to provide accurate and current information, {Application.ORG_DISPLAY_NAME} cannot guarantee the accuracy of flight schedules, fares, or any other particulars displayed on our platform. We strongly recommend cross-verifying information with the respective airlines.</p>
       <p><strong>Refunds and Cancellations:</strong></p>
       <ul>
        <li>Policies concerning cancellations and refunds are contingent on the airline and fare category. It is incumbent upon you to meticulously comprehend and assimilate these terms before finalising a booking.</li>
        <li>{Application.ORG_DISPLAY_NAME} disclaims any responsibility for charges or penalties levied by airlines in the event of changes or cancellations.</li>
       </ul>
       <p><strong>Limitation of Liability:</strong></p>
       <p>{Application.ORG_DISPLAY_NAME} assumes no liability for losses, damages, delays, or expenses resulting from actions or oversights by airlines, hotels, or other third-party service providers.</p>
       <p>Under no circumstances shall {Application.ORG_DISPLAY_NAME} be held accountable for any indirect, consequential, or punitive damages arising from your utilisation of our services.</p>
       <p><strong>Modifications to Terms and Conditions:</strong></p>
       <p>{Application.ORG_DISPLAY_NAME} retains the right to amend or update these Terms & Conditions at its discretion. Regularly reviewing these terms rests solely on your shoulders.</p>
       <p>By utilising our services and website, you affirm having perused, comprehended, and concurred with these Terms & Conditions.</p>
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default Terms;