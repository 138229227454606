import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Privacy = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Privacy Policy')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Privacy Policy</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Privacy Policy</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <div>
  
       <p>At {Application.ORG_DISPLAY_NAME}, safeguarding your privacy remains our top priority. Our Privacy Policy underscores our unwavering dedication to the protection of your personal information. Be assured that any data you entrust to us will be treated with the highest level of care and security. We collect only essential information necessary to facilitate your bookings and elevate your travel experience. Your confidence in us is priceless, and we are fully committed to upholding the confidentiality and safety of your information.</p>
       <p>Our Privacy Policy provides an overview of how we gather, utilise, and shield your information when you engage with our website and services.</p>
       <p><strong>Information Gathering:</strong></p>
       <p>As you explore our website or make use of our services, certain information is gathered. This may encompass details like your name, contact particulars, payment data, and travel preferences. We employ this information to assist with bookings, personalise your journey, and convey important updates.</p>
       <p><strong>Utilisation of Data:</strong></p>
       <p>Your information is employed to process bookings, offer customer support, and refine our services. Additionally, we might utilise your data for analytical purposes to enhance user experiences and optimise our offerings.</p>
       <p><strong>Sharing of Information:</strong></p>
       <p>In the course of fulfilling your bookings, we might share your data with third parties such as airlines and payment processors. Please rest assured, we do not vend or lease your information to external entities for marketing objectives.</p>
       <p><strong>Data Security:</strong></p>
       <p>We apply industry-standard security protocols to safeguard your data against unauthorised access, tampering, or disclosure. Nevertheless, complete security of online transmissions cannot be guaranteed, and absolute data security remains beyond our assurance.</p>
       <p><strong>Links to External Sites:</strong></p>
       <p>Our website could contain links directing you to third-party websites. We are not accountable for the privacy practices or content of such external sites. We advise you to review their privacy policies before disclosing any information.</p>
       <p>At {Application.ORG_DISPLAY_NAME}, we are resolutely dedicated to preserving your privacy while enhancing your travel experience.</p>
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default Privacy;